import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

import { UiModule } from '@tx/ui';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APOLLO_FLAGS, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { createApollo, GraphqlApiModule } from '@tx/api';
import { AppRoutingModule } from './app-routing.module';
import { PartyModule } from './party/party.module';

import { AuthConfig, OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';

import { CommonModule } from '@angular/common';
import { TokenInterceptor } from './token.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { NotificationService } from '../../../../libs/ui/src/lib/notification/notification.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SettingsModule } from './settings/settings.module';
import { InjectionToken } from '@angular/core';
import { S3Module } from './services/s3/s3.module';
import { AuthGuard } from './party/auth-guard.service';
import { authAppInitializerFactory } from './party/auth-app-initializer.factory';
import { AuthService } from './party/auth.service';
import { authConfig } from './party/auth-config';
export const APP_CONFIG_TOKEN = new InjectionToken<any>('config');

function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastrModule.forRoot(), // ToastrModule added
    HttpClientModule,
    MatProgressSpinnerModule,
    UiModule,
    GraphqlApiModule,
    PartyModule,
    SettingsModule,
    S3Module,
    OAuthModule.forRoot()
  ],
  providers: [
    { provide: OAuthStorage, useFactory: storageFactory },
    { provide: AuthConfig, useValue: authConfig },
    { provide: APP_INITIALIZER, useFactory: authAppInitializerFactory, deps: [AuthService], multi: true },
    { provide: APP_CONFIG_TOKEN, useValue: environment },
    {
      provide: APOLLO_FLAGS,
      useValue: {
        useInitialLoading: false,
        useMutationLoading: false // enable it here
      }
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, OAuthService, NotificationService, APP_CONFIG_TOKEN]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: 'env', // you can also use InjectionToken
      useValue: environment
    },
    NotificationService,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
